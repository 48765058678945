// category search
$(document).on("turbolinks:load", function () {
  $(".category-item_title").click(function () {
    if($(this).attr("aria-expanded") === "true") {
      $(this).addClass("active");
    } else {
      $(this).removeClass("active");
    }
  });
});

// hide, show more category search pc
$(document).on("turbolinks:load", function () {
  $(".subcategory_button-collapse").click(function () {
    $(this).siblings(".hide-default").toggleClass("active");
    $(this).find(".icon-collapse").toggleClass("rotate-0");
    var status_collapse = $(this).siblings(".hide-default").hasClass("active");
    if (status_collapse) {
      $(this).find(".text-status").html("少なく表示");
      return true;
    }
    $(this).find(".text-status").html("すべて表示");
  });
});

$("#btn-submit-free-search").on("click", function () {
  var value_keyword = $("input[name=keyword]").val()
  if (value_keyword.length > 0) {
    window.location.href = Routes.jobs_path({
      keyword: value_keyword,
    });
  } else {
    window.location.href = Routes.jobs_path();
  }
  $(this).prop("disabled", false);
});
